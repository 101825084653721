$ = jQuery;
/*Functions*/

/**
 * Google Rating
 */
function starsHover() {
    var ratingInner = $(".stars_rating.g_rating .g_rating_inner"),
        ratingInnerWidth = ratingInner.innerWidth(),
        ratingBlock = $(".stars_rating.g_rating"),
        id = $(".g_rating_block .count_number").attr('data-id');
    ratingCount = $(".g_rating_block .count_number span");
    ratingBlock.on("click", function() {
        if (!getCookie('added_rating' + id) && !$(this).data("clicked")) {
            ratingCount.text(parseInt(ratingCount.text()) + 1);
            console.log(ratingCount.text());
            var data = {
                'action': 'add_rating_count',
                'count': ratingCount.html(),
                'id': id
            };
            $.ajax({
                url: '/wp-admin/admin-ajax.php',
                data:data,
                type:'POST',
                success:function(data){
                    if( data ) {
                        ratingCount.text(data);
                    }
                }
            });
        }
        setCookie('added_rating' + id,'true',10);
        $(this).data("clicked", !0)
    });
    if(!getCookie('added_rating' + id) && !$(this).data("clicked")){
        ratingBlock.on("mousemove", function(e) {
            if (!$(this).data("clicked")) {
                var ratingInnerWidth = e.clientX - $(this).offset().left;
                ratingInner.css("width", ratingInnerWidth)
            }
        });
        ratingBlock.on("mouseleave", function(e) {
            ratingInner.css("width", ratingInnerWidth)
        })
    }
}
/*Scroll top*/
function showScrollTop() {
    if ($(this).scrollTop() > 100) {
        $("#to_top").addClass('active');
    }
    else {
        $("#to_top").removeClass('active');
    }
}

$(document).ready(function () {
    "use strict";
    var siteBody = $("body");
    /*Init*/
    siteBody.addClass(currentBrowser()).addClass(detectmob());
    showScrollTop();
    $(window).scroll(function () {
        showScrollTop();
    });
    if($(".g_rating")[0]){
        starsHover();
    }
    /*Events*/
    // $(window).on('load', function () {
    //
    // });
    // $(window).bind('resize', function () {
    //
    // });

    /*Scroll top Click*/
    $("#to_top").on("click","a", function (event) {
        event.preventDefault();
        var id  = $(this).attr('href'),
            top = $(id).offset().top;
        $('html, body').animate({scrollTop: top}, 1000);
    });
    /*Menu*/
    $("#menuOpen button.hamburger").on('click',function (e) {
        $('#mainMenu').toggleClass("opened");
        $(this).parent().toggleClass("opened");
        $(this).toggleClass('is-active');
    });
    $('#mainMenu li.menu-item-has-children').append('<span class="subMenuButton i-angle-down"></span>');
    $('.subMenuButton').on('click',function () {
        var t = $(this);
        if(t.hasClass('open')) {
            t.removeClass('open').prev().slideUp(300);
        } else {
            t.removeClass('open').prev().slideDown(300);
            t.addClass('open');
        }
    });
    //Menu Close
    $(document).mouseup(function (e){
        var div = $("#mainMenu,#menuOpen");
        if (!div.is(e.target)
            && div.has(e.target).length === 0) {
            div.removeClass('opened');
            $('.hamburger').removeClass('is-active');
        }
    });
    //WPCF7
    $(this).on('click', '.wpcf7-not-valid-tip', function () {
        $(this).prev().trigger('focus');
        $(this).fadeOut(500, function () {
            $(this).remove();
        });
    });
    //Privacy
    if(!getCookie('privacy')){
        var privacy = $('#privacy');
        privacy.show();
    }
    $('#privacy .privacy_close').on('click',function () {
        $('#privacy').addClass('hide');
        setCookie('privacy','true',1);
    });
    
    $('.owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        dots:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            1000:{
                items:3
            }
        }
    })

});