$ = jQuery;
//SVG
$=jQuery;$('img.img_svg').each(function(){var $img=jQuery(this);var imgID=$img.attr('id');var imgClass=$img.attr('class');var imgURL=$img.attr('src');$.get(imgURL,function(data){var $svg=jQuery(data).find('svg');if(typeof imgID!=='undefined'){$svg=$svg.attr('id',imgID)}
    if(typeof imgClass!=='undefined'){$svg=$svg.attr('class',imgClass+' replaced-svg')}
    $svg=$svg.removeAttr('xmlns:a');if(!$svg.attr('viewBox')&&$svg.attr('height')&&$svg.attr('width')){$svg.attr('viewBox','0 0 '+$svg.attr('height')+' '+$svg.attr('width'))}
    $img.replaceWith($svg)},'xml')});
// browsers
function currentBrowser(){var o=!!window.opr&&!!opr.addons||!!window.opera||navigator.userAgent.indexOf(" OPR/")>=0,e="undefined"!=typeof InstallTrigger,i=/constructor/i.test(window.HTMLElement)||"[object SafariRemoteNotification]"===(!window.safari||"undefined"!=typeof safari&&safari.pushNotification).toString(),n=!!document.documentMode,r=!n&&!!window.StyleMedia,t=!!window.chrome&&!!window.chrome.webstore,d=(t||o)&&!!window.CSS;return o?"opera":e?"firefox":i?"safari":n?"ie":r?"edge":t?"chrome":d?"blink":void 0}

//Mob
function detectmob(){return navigator.userAgent.match(/Android/i)?"android":navigator.userAgent.match(/webOS/i)?"webOS":navigator.userAgent.match(/iPhone/i)?"iPhone":navigator.userAgent.match(/iPad/i)?"iPad":navigator.userAgent.match(/iPod/i)?"iPod":navigator.userAgent.match(/BlackBerry/i)?"blackBerry":!!navigator.userAgent.match(/BlackBerry/i)&&"windowsPhone"}

/*Select*/
$('select').each(function(){
    var $this = $(this), numberOfOptions = $(this).children('option').length;

    $this.addClass('select-hidden');
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');

    var $styledSelect = $this.next('div.select-styled');
    $styledSelect.text($this.children('option').eq(0).text());

    var $list = $('<ul />', {
        'class': 'select-options'
    }).insertAfter($styledSelect);

    for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
            text: $this.children('option').eq(i).text(),
            rel: $this.children('option').eq(i).val()
        }).appendTo($list);
    }

    var $listItems = $list.children('li');

    $styledSelect.click(function(e) {
        e.stopPropagation();
        $('div.select-styled.active').not(this).each(function(){
            $(this).removeClass('active').next('ul.select-options').hide();
        });
        $(this).toggleClass('active').next('ul.select-options').toggle();
    });

    $listItems.click(function(e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel')).attr('data-select-id',$(this).attr('rel'));
        $list.hide();
    });

    $(document).click(function() {
        $styledSelect.removeClass('active');
        $list.hide();
    });
});

/*COOKIES*/
function setCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = '';
    document.cookie = name+"="+value+expires+"; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return false;
}
function deleteCookie(name) {
    setCookie(name,"",-1);
}